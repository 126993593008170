import React from 'react'
import Header from './header'
import {Link} from 'gatsby'

import '../styles/layout.css'
import styles from './layout.module.css'

import { FontAwesomeIcon } from '../../node_modules/@fortawesome/react-fontawesome'
import { faTwitter,faYoutube,faBehance,faLinkedin,faDribbble,faPinterest } from '../../node_modules/@fortawesome/free-brands-svg-icons'

const twitterIcon = <FontAwesomeIcon icon={faTwitter} />
const youtubeIcon = <FontAwesomeIcon icon={faYoutube} />
const behanceIcon = <FontAwesomeIcon icon={faBehance} />
const linkedinIcon = <FontAwesomeIcon icon={faLinkedin} />
const dribbbleIcon = <FontAwesomeIcon icon={faDribbble} />
const pinterestIcon = <FontAwesomeIcon icon={faPinterest} />

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, subtitle, logo, social}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} logo={logo} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>
        <p className={styles.siteSubtitle}>{subtitle}</p>
        { social && social.twitter !== 0 ?
        <ul className={styles.social}>
          <li>
            <Link to={"https://twitter.com/" + social.twitter} title="Twitter">{twitterIcon}</Link>
          </li>
          <li>
            <Link to={"https://www.linkedin.com/in/" + social.linkedin} title="LinkedIn">{linkedinIcon}</Link>
          </li>
          <li>
            <Link to={"https://dribbble.com/" + social.dribbble} title="Dribbble">{dribbbleIcon}</Link>
          </li>
          <li>
            <Link to={"https://www.behance.net/" + social.behance} title="Behance">{behanceIcon}</Link>
          </li>
          <li>
            <Link to={"https://www.pinterest.co.uk/" + social.pinterest} title="Pinterest">{pinterestIcon}</Link>
          </li>
          <li>
            <Link to={"https://www.youtube.com/channel/" + social.youtube} title="YouTube">{youtubeIcon}</Link>
          </li>
        </ul>
        : null }
          &copy; {new Date().getFullYear()} Ashley Chin
        </div>
      </div>
    </footer>
  </>
)

export default Layout
