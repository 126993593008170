import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import {buildImageObj, getBlogUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle, logo}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <div className={styles.branding}>
        <Link to='/'>
        {logo && logo.asset && (
          <img
            src={imageUrlFor(buildImageObj(logo))
              .width(200)
              .height(200)
              .fit('crop')
              .auto('format')
              .url()}
            alt={logo.alt}
          />
         )}
        </Link>
      </div>
      
      <div className={styles.siteTitle}>
        <Link to='/'>
          <h1>{siteTitle}</h1>
        </Link>
      </div>

      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
        <Icon symbol='hamburger' />
      </button>

      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to='/categories/arts/' title="Arts"><span>Arts </span>🎨</Link>
          </li>
          <li>
            <Link to='/categories/digital/' title="Digital"><span>Digital </span>💻</Link>
          </li>
          <li>
            <Link to='/categories/languages/' title="Languages"><span>Languages </span>💬</Link>
          </li>
          <li>
            <Link to='/archive/' title="Archive"><span>Archive </span>🗄️</Link>
          </li>
          <li>
            <Link to='/portfolio/' title="Portfolio"><span>Portfolio </span>💎</Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
